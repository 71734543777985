<template>
    <v-app style="background: transparent" :class="{}">
        <!-- begin:: Content Head -->
        <KTSubheader
                v-if="nav.subheaderDisplay"
                v-bind:breadcrumbs="nav.breadcrumbs"
                v-bind:title="nav.pageTitle"
        />
        <!-- end:: Content Head -->
        <div class="d-flex flex-column-fluid">
            <!--begin::Container-->
            <div class="container">
                <div class="card card-custom card-transparent">
                    <div class="card-body p-0">
                        <!--begin::Wizard-->
                        <div class="wizard wizard-4" id="kt_wizard" data-wizard-state="first" data-wizard-clickable="true">
                            <!--begin::Wizard Nav-->
                            <div class="wizard-nav">
                                <div class="wizard-steps">
                                    <div class="wizard-step" data-wizard-type="step" v-bind:data-wizard-state="step == 1?'current':'pending'">
                                        <div class="wizard-wrapper">
                                            <div class="wizard-number">1</div>
                                            <div class="wizard-label">
                                                <div class="wizard-title">确认信息</div>
                                                <div class="wizard-desc">确认站点或店铺授权信息</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="wizard-step" data-wizard-type="step" v-bind:data-wizard-state="step == 2?'current':'pending'">
                                        <div class="wizard-wrapper">
                                            <div class="wizard-number">2</div>
                                            <div class="wizard-label">
                                                <div class="wizard-title">授权平台</div>
                                                <div class="wizard-desc">选择站点或店铺授权平台</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="wizard-step" data-wizard-type="step" v-bind:data-wizard-state="step == 3?'current':'pending'">
                                        <div class="wizard-wrapper">
                                            <div class="wizard-number">3</div>
                                            <div class="wizard-label">
                                                <div class="wizard-title">授权配置</div>
                                                <div class="wizard-desc">配置站点或店铺授权信息</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="wizard-step" data-wizard-type="step" v-bind:data-wizard-state="step == 4?'current':'pending'">
                                        <div class="wizard-wrapper">
                                            <div class="wizard-number">4</div>
                                            <div class="wizard-label">
                                                <div class="wizard-title">授权提交</div>
                                                <div class="wizard-desc">确认和提交授权配置信息</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!--end::Wizard Nav-->

                            <!--begin::Card-->
                            <div class="card card-custom card-shadowless rounded-top-0">
                                <!--begin::Body-->
                                <div class="card-body p-0">
                                    <div class="row justify-content-center py-8 px-8 py-lg-15 px-lg-10">
                                        <div class="col-xl-12 col-xxl-10">
                                            <div class="col-xl-4" style="margin:auto;" v-if="step == 1">
                                                <div class="form-group row my-2">
                                                    <label class="col-4 col-form-label">站点名称:</label>
                                                    <div class="col-8">
                                                        <span class="form-control-plaintext font-weight-bolder">{{site.site_name}}</span>
                                                    </div>
                                                </div>

                                                <div class="form-group row my-2">
                                                    <label class="col-4 col-form-label">站点地址:</label>
                                                    <div class="col-8">
                                                        <span class="form-control-plaintext font-weight-bolder">
                                                            <a :href="'https://'+site.site_url" target="_blank">
                                                                {{site.site_url}}
                                                            </a>
                                                        </span>
                                                    </div>
                                                </div>
                                                <div class="form-group row my-2">
                                                    <label class="col-4 col-form-label">站点描述:</label>
                                                    <div class="col-8">
                                                        <span class="form-control-plaintext font-weight-bolder">{{site.site_desc}}</span>
                                                    </div>
                                                </div>
                                                <div class="form-group row my-2">
                                                    <label class="col-4 col-form-label">站点所属平台:</label>
                                                    <div class="col-8">
                                                        <span class="form-control-plaintext font-weight-bolder">{{site.site_platform_text}}</span>
                                                    </div>
                                                </div>
                                                <div class="form-group row my-2">
                                                    <label class="col-4 col-form-label">站点授权:</label>
                                                    <div class="col-8">
                                                        <span class="form-control-plaintext font-weight-bolder">{{site.site_auth_text}}</span>
                                                    </div>
                                                </div>
                                                <div class="form-group row my-2">
                                                    <label class="col-4 col-form-label">创建人:</label>
                                                    <div class="col-8">
                                                        <span class="form-control-plaintext font-weight-bolder">{{site.creator}}</span>
                                                    </div>
                                                </div>
                                                <div class="form-group row my-2">
                                                    <label class="col-4 col-form-label">创建时间:</label>
                                                    <div class="col-8">
                                                        <span class="form-control-plaintext font-weight-bolder">{{site.create_time}}</span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-xl-10" style="margin:auto;" v-if="step == 2">

                                                <div v-for="(item) in plat_form_list"
                                                     v-bind:key="item.value"
                                                     :title="item.text"
                                                     class="plat_form_icon"
                                                     :class="['plat_form_icon_' + item.name, site.site_platform == item.value?'plat_active':'']"
                                                     @click="selectPlatForm(item.value)"
                                                >
                                                    <div>
                                                        <i class="ki ki-bold-check-1"></i>
                                                    </div>
                                                </div>

                                            </div>

                                            <div class="col-xl-10" style="margin:auto;" v-if="step == 3">
                                                <div v-if="site.site_platform == 4 || site.site_platform == 3">
                                                    <form class="form fv-plugins-bootstrap fv-plugins-framework" ref="api_config_form" id="api_config_form">

                                                        <div class="form-group row">
                                                            <label class="col-lg-3 col-form-label">数据接口地址 <span class="text-danger">*</span>:</label>
                                                            <div class="col-lg-6">
                                                                <input type="text" class="form-control" placeholder="请输入数据接口地址" v-model="api_config.url" name="apiconfig_url">
                                                                <span class="form-text text-muted"></span>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>

                                            <div class="col-xl-10" style="margin:auto;" v-if="step == 4">
                                                <h3 class="mb-10 font-weight-bold text-dark">站点基本信息</h3>
                                                <div class="form-group row my-2">
                                                    <label class="col-4 col-form-label">站点名称:</label>
                                                    <div class="col-8">
                                                        <span class="form-control-plaintext font-weight-bolder">{{site.site_name}}</span>
                                                    </div>
                                                </div>

                                                <div class="form-group row my-2">
                                                    <label class="col-4 col-form-label">站点地址:</label>
                                                    <div class="col-8">
                                                        <span class="form-control-plaintext font-weight-bolder">
                                                            <a :href="'https://'+site.site_url" target="_blank">
                                                                {{site.site_url}}
                                                            </a>
                                                        </span>
                                                    </div>
                                                </div>
                                                <div class="form-group row my-2">
                                                    <label class="col-4 col-form-label">站点描述:</label>
                                                    <div class="col-8">
                                                        <span class="form-control-plaintext font-weight-bolder">{{site.site_desc}}</span>
                                                    </div>
                                                </div>
                                                <div class="form-group row my-2">
                                                    <label class="col-4 col-form-label">站点所属平台:</label>
                                                    <div class="col-8">
                                                        <span class="form-control-plaintext font-weight-bolder">{{site.site_platform_text}}</span>
                                                    </div>
                                                </div>

                                                <div class="form-group row my-2">
                                                    <label class="col-4 col-form-label">创建人:</label>
                                                    <div class="col-8">
                                                        <span class="form-control-plaintext font-weight-bolder">{{site.creator}}</span>
                                                    </div>
                                                </div>
                                                <div class="form-group row my-2">
                                                    <label class="col-4 col-form-label">创建时间:</label>
                                                    <div class="col-8">
                                                        <span class="form-control-plaintext font-weight-bolder">{{site.create_time}}</span>
                                                    </div>
                                                </div>

                                                <h3 class="mb-10 font-weight-bold text-dark" style="margin-top:60px">授权信息</h3>

                                                <div class="form-group row my-2">
                                                    <label class="col-4 col-form-label">站点授权:</label>
                                                    <div class="col-8">
                                                        <span class="form-control-plaintext font-weight-bolder">{{site.site_auth_text}}</span>
                                                    </div>
                                                </div>

                                                <div class="form-group row my-2">
                                                    <label class="col-4 col-form-label">数据接口地址:</label>
                                                    <div class="col-8">
                                                        <span class="form-control-plaintext font-weight-bolder">{{api_config.url}}</span>
                                                    </div>
                                                </div>

                                                <div class="form-group row my-2">
                                                    <label class="col-4 col-form-label">授权时间:</label>
                                                    <div class="col-8">
                                                        <span class="form-control-plaintext font-weight-bolder">{{api_config.date}}</span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="d-flex justify-content-between border-top pt-10 mt-15">
                                                <div class="mr-2">
                                                    <button v-if="previous" type="button" id="prev-step" class="btn btn-light-primary font-weight-bolder px-9 py-4" @click="prevClick()">Previous</button>
                                                </div>
                                                <div>
                                                    <button v-if="submit" ref="data_form_submit" type="button" id="next-step" class="btn btn-primary font-weight-bolder px-9 py-4" @click="submitClick()">保存</button>
                                                    <button v-if="next"  type="button" id="next-step" class="btn btn-primary font-weight-bolder px-9 py-4" @click="nextClick()">Next</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!--end::Body-->
                            </div>
                            <!--end::Card-->
                        </div>
                        <!--end::Wizard-->
                    </div>
                </div>
            </div>
        </div>
    </v-app>
</template>
<style lang="css">
    @import "~@/assets/css/common.css";
</style>
<script>
    import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
    import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
    import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
    import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";
    import KTSubheader from "@/view/layout/subheader/Subheader.vue";
    import KTUtil from "@/assets/js/components/util";


    import {getSite, authSite} from '@/api/site';

    export default {
        name: "auth_site_view",
        components: {
            KTSubheader
        },
        created: function(){
            var _this = this;
            getSite({'id': _this.$route.query.id}).then(function(res) {
                if(res.response){
                    _this.site.id = res.response.id;
                    _this.site.site_name = res.response.site_name;
                    _this.site.site_desc = res.response.site_desc;
                    _this.site.site_url = res.response.site_url;
                    _this.site.site_platform = res.response.site_platform;
                    _this.site.creator = res.response.creator;
                    _this.site.site_auth = res.response.site_auth;
                    _this.site.create_time = KTUtil.timestamp2date(res.response.create_time);

                    //判断是否已经授权
                    if(res.response.auth_config){
                        var config = JSON.parse(res.response.auth_config);
                        if(config){
                            if(_this.site.site_platform == 4 ||   _this.site.site_platform == 3) {
                                _this.api_config.url = config.config.api_url;
                                _this.api_config.date = KTUtil.timestamp2date(res.response.auth_create_time);
                            }

                            _this.step = 4;
                            _this.next = false;
                            _this.submit = false;
                            _this.previous = true;
                        }
                    }
                }
            });
        },
        watch: {
            site:{
                handler(val, oldVal){
                    this.site.site_platform_text = KTUtil.getPlatForm(this.site.site_platform);
                    this.site.site_auth_text = this.site.site_auth == 0?'未授权':'已授权';
                },
                deep: true
            }
        },
        data() {
            return {
                nav: {
                    subheaderDisplay: true,
                    breadcrumbs : [{'id':'', 'route':'/site', 'title':'站点管理'}, {'id':'', 'route':'/site/authsite', 'title':'站点授权'}],
                    pageTitle: '系统'
                },
                site: {
                    id: '',
                    site_name: '',
                    site_desc: '',
                    site_url: '',
                    site_platform: '',
                    site_platform_text: '',
                    site_auth: '',
                    site_auth_text: '',
                    creator: '',
                    create_time: ''
                },
                api_config: {
                    url:'',
                    date: KTUtil.timestamp2date(new Date().getTime() / 1000)
                },
                plat_form_list: KTUtil.getPlatForm(),
                step:1,
                previous: false,
                next: true,
                submit: false
            }
        },
        mounted() {

        },
        methods: {
            prevClick: function(){
                if(this.step <= 2){
                    this.step = 1;
                    this.previous = false;
                } else {
                    this.step--;
                    this.next = true;
                    this.submit = false;
                }
            },
            nextClick: function(){
                if(this.step >= 3){

                    if(this.site.site_platform == 4 || this.site.site_platform == 3) {
                        const data_form = KTUtil.getById("api_config_form");
                        this.fv = formValidation(data_form, {
                            fields: {
                                apiconfig_url: {
                                    validators: {
                                        notEmpty: {
                                            message: "数据接口地址不能为空"
                                        }
                                    }
                                }
                            },
                            plugins: {
                                trigger: new Trigger(),
                                submitButton: new SubmitButton(),
                                bootstrap: new Bootstrap()
                            }
                        });
                        this.fv.on("core.form.valid", () => {
                            this.step = 4;
                            this.next = false;
                            this.submit = true;
                        });
                        this.fv.validate();


                    }
                } else {
                    this.step++;
                    this.previous = true;
                }

            },
            selectPlatForm: function(platForm){
                this.site.site_platform = platForm;
            },
            submitClick: function() {
                var _this = this;

                //按钮加载动画
                const submitButton = this.$refs["data_form_submit"];
                submitButton.classList.add("spinner", "spinner-light", "spinner-right");

                var auth_config = '';
                if(_this.site.site_platform == 4 || _this.site.site_platform == 3){
                    auth_config = '{"type": "api", "config": { "api_url": "'+ _this.api_config.url +'" }}';
                }
                var data = {
                    'id': _this.site.id,
                    'site_platform': _this.site.site_platform,
                    'auth_config': auth_config
                };
                authSite(data).then(function(res){
                    if (res) {
                        KTUtil.toast(_this, '结果提醒', '保存成功', 'success');
                    }
                    submitButton.classList.remove(
                        "spinner",
                        "spinner-light",
                        "spinner-right"
                    );
                });
            }
        }
    }
</script>
