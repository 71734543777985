import request from "./request";

export function getSiteList(params){
    return request({
        url: 'site/getSiteList',
        method: 'post',
        data: params
    });
}


export function addSite(params){
    return request({
        url: 'site/addSite',
        method: 'post',
        data: params
    });
}

export function updateSite(params){
    return request({
        url: 'site/updateSite',
        method: 'post',
        data: params
    });
}

export function deleteSite(params){
    return request({
        url: 'site/deleteSite',
        method: 'post',
        data: params
    });
}

export function getSite(params){
    return request({
        url: 'site/getSite',
        method: 'post',
        data: params
    });
}

export function authSite(params){
    return request({
        url: 'site/authSite',
        method: 'post',
        data: params
    });
}
