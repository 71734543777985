<template>
    <div class="card card-custom example example-compact">
        <div class="card-header">
            <h3 class="card-title">{{title}}</h3>
            <button type="button" class="close" @click="cancelForm()">
                <i aria-hidden="true" class="ki ki-close"></i>
            </button>
        </div>

        <!--begin::Form-->
        <form class="form fv-plugins-bootstrap fv-plugins-framework" ref="dataForm" id="dataForm">
            <div class="card-body" style="max-height:500px;overflow:auto;">
                <div class="form-group row">
                    <label class="col-lg-3 col-form-label">站点名称 <span class="text-danger">*</span>:</label>
                    <div class="col-lg-6">
                        <input type="text" class="form-control" placeholder="请输入站点名称" v-model="data_form.site_name" name="site_name">
                        <span class="form-text text-muted"></span>
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-lg-3 col-form-label">站点地址 <span class="text-danger">*</span>:</label>
                    <div class="col-lg-6">
                        <input type="text" class="form-control" placeholder="请输入站点地址" v-model="data_form.site_url" name="site_url">
                        <span class="form-text text-muted"></span>
                    </div>
                </div>


                <div class="form-group row">
                    <label class="col-lg-3 col-form-label">站点描述 :</label>
                    <div class="col-lg-6">
                        <input type="text" class="form-control" placeholder="请输入站点描述" v-model="data_form.site_desc" name="site_desc">
                        <span class="form-text text-muted"></span>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-lg-3 col-form-label">站点所属平台 <span class="text-danger">*</span>:</label>
                    <div class="col-lg-6">
                        <b-form-select class="form-control" v-model="data_form.site_platform" :options="platform_list" placeholder="请选择站点所属平台"
                                       name="site_platform" :disabled="select_status">

                        </b-form-select>
                    </div>
                </div>

            </div>

            <div class="card-footer">
                <button ref="data_form_submit" class="btn btn-primary mr-2">保存</button>
                <button class="btn btn-secondary" type="reset" @click="cancelForm()">关闭</button>
            </div>
        </form>
        <!--end::Form-->
    </div>
</template>
<script>
    import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
    import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
    import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
    import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";
    import KTUtil from "@/assets/js/components/util";

    import {updateSite} from '@/api/site';


    export default {
        name: "site_update",
        props: {
            title: String,
            dialog_data: Object
        },
        created:function() {

        },
        components :{

        },
        data() {
            return {
                data_form:{
                    id: this.dialog_data.form_data.id,
                    site_name: this.dialog_data.form_data.site_name,
                    site_url: this.dialog_data.form_data.site_url,
                    site_desc: this.dialog_data.form_data.site_desc,
                    site_platform: this.dialog_data.form_data.site_platform
                },
                platform_list: KTUtil.getPlatForm(),
                select_status: this.dialog_data.form_data.site_auth == 0?false:true
            }
        },
        mounted() {
            const data_form = KTUtil.getById("dataForm");

            //表单数据验证
            this.fv = formValidation(data_form, {
                fields: {
                    site_name: {
                        validators: {
                            notEmpty: {
                                message: "站点名称不能为空"
                            }
                        }
                    },
                    site_url: {
                        validators: {
                            notEmpty: {
                                message: "站点路径不能为空"
                            }
                        }
                    },
                    site_platform: {
                        validators: {
                            notEmpty: {
                                message: "站点所属平台不能为空"
                            }
                        }
                    },
                },
                plugins: {
                    trigger: new Trigger(),
                    submitButton: new SubmitButton(),
                    bootstrap: new Bootstrap()
                }
            });
            this.fv.on("core.form.valid", () => {
                var _this = this;

                //按钮加载动画
                const submitButton = this.$refs["data_form_submit"];
                submitButton.classList.add("spinner", "spinner-light", "spinner-right");

                updateSite(_this.data_form).then(function (res){
                    if (res) {
                        KTUtil.toast(_this, '结果提醒', '保存成功', 'success');
                        data_form.reset();
                        _this.$emit('opeareResultHandler', 1);
                    }
                    submitButton.classList.remove(
                        "spinner",
                        "spinner-light",
                        "spinner-right"
                    );
                });
            });
        },
        methods: {
            cancelForm(){
                this.$emit('opeareResultHandler', 0);

            },
        }
    }
</script>
